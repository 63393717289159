import request from '@/auth/jwt/request'

/**
 * @description 项目管理 -- 项目管理列表
 * @param {Object} param params {Object} 传值参数
 */
export const projectListApi = params => { return request('project/list', 'get', params) }

/**
 * @description 项目管理 -- 增加修改项目
 * id = 0 新增
 * @param {Object} param params {Object} 传值参数
 */
export const projectSaveApi = data => { return request('project/save', 'post', {}, data) }

/**
 * @description 项目管理 -- 项目状态管理
 * @param {Object} param params {Object} 传值参数
 */
export const projectStatusApi = params => { return request('project/status', 'get', params) }

/**
 * @description 项目管理 -- 项目详情
 * @param {Object} param params {Object} 传值参数
 */
export const projectInfoApi = params => { return request('project/info', 'get', params) }
/**
 * @description 项目管理 -- 删除项目
 * { id = x }
 */
export const projectDeleteApi = data => { return request('project/delete', 'delete', data) }