import { ref, reactive, watch, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
// import store from '@/store'
// 接口地址
import {
  projectListApi,
  projectDeleteApi,
  projectStatusApi,
  projectSaveApi,
} from '@/api/project'
// 消息提醒
import MsgNotice from '@/comps/MsgNotice'
// 对话框提醒
import SweetAlert from '@/comps/SweetAlertModal'

export default function useFunction($can) {
  const { router } = useRouter()
  /* 页面文本信息 后期 多语言预留 */
  const moduleName = ref('project')
  const moduleText = '项目'

  // 查询参数，自动生成界面
  const searchParamsBase = [
    { param: 'name', component: 'input', type: 'text', label: '项目名称', value: '', },
    // { param: 'id', component: 'input', type: 'number', label: '项目id', value: '', },
  ]
  /* 页面文本信息 后期 多语言预留 */

  // 左侧按钮配置
  const buttonLeftOptions = [
    // { label: '导入', variant: 'outline-primary', icon: 'UploadCloudIcon', event: 'upload' },
    // { label: '下载', variant: 'outline-primary', icon: 'DownloadCloudIcon', event: 'download' },
  ]
  // 右侧按钮配置
  const buttonRightOptions = [
    { label: '新建项目', variant: 'primary', icon: 'PlusIcon', event: 'add', rule: { action: 'save', subject: 'extension-project-list' } },
    // { label: '导入', variant: 'outline-primary', icon: 'UploadCloudIcon', event: 'upload' },
  ]

  // 表格操作菜单
  const menuData = [
    { clickType: 'edit', icon: 'EditIcon', text: '编辑', rule: { action: 'save', subject: 'extension-project-list' } },
    { clickType: 'enable', icon: 'LockIcon', text: '启用', status: [false], rule: { action: 'status', subject: 'extension-project-list' } },
    { clickType: 'disable', icon: 'UnlockIcon', text: '禁用', status: [true], rule: { action: 'status', subject: 'extension-project-list' } },
    { clickType: 'delete', icon: 'Trash2Icon', text: '删除', rule: { action: 'status', subject: 'extension-project-list' } },
  ]

  // 接口提示信息
  const MsgText = {
    // 获取数据
    fetchData: {
      success: { title: '', text: '' },
      fail: { title: `${moduleText}列表获取失败`, text: null }
    },
    // 状态管理
    statusEvent: {
      success: { title: `${moduleText}状态更新成功`, text: null },
      fail: { title: `${moduleText}状态更新失败`, text: null }
    },
    // 新增编辑
    editEvent: {
      success: { title: `${moduleText}编辑成功`, text: null },
      fail: { title: `${moduleText}编辑失败`, text: null }
    },
    // 删除操作
    deleteEvent: {
      success: { title: `${moduleText}删除成功`, text: null },
      fail: { title: `${moduleText}删除失败`, text: null },
      confirm: { title: `您确认需要删除此${moduleText}吗?`, text: '如果存在数据关联, 删除操作将会失败, 请知悉!' },
    },
  }

  // 状态参数
  const options = [
    { text: '启用', value: true, disabled: false },
    { text: '禁用', value: false, },
  ]

  // 表单页面参数，自动生成界面
  const blankEditParams = reactive([
    { param: 'id', component: 'input', number: true, type: 'number', label: '项目ID', value: 0, display: true, },
    { param: 'name', component: 'input', type: 'text', label: '项目名称', value: '', rules: 'required', },
    { param: 'pic', component: 'pic', label: '项目封面', value: '', rules: 'required', },
    { param: 'slidePic', component: 'pic', label: '遮盖动效图', value: '', rules: 'required', },
    { param: 'description', component: 'ueditor', label: '项目描述', value: '', },
    { param: 'status', component: 'radio', label: '状态', value: true, radioOptions: options, rules: 'required', },
  ])
  // 编辑或者新增对象
  const editValue = ref({})
  // 窗口控制
  const isShowSidebarActive = ref(false)

  /* 事件分发 */

  // 主控制按钮事件分发
  const mainActionEvent = type => {
    switch (type) {
      case 'add':
        blankEditParams.forEach(item => { editValue.value[item.param] = item.value })
        isShowSidebarActive.value = true
        break
      case 'upload': null
        break
      case 'download': null
        break
      default: null
    }
  }

  // 组件事件分发
  const searhPanelButtonEvent = clickButtonType => {
    switch (clickButtonType) {
      case 'search': searchFunction()
        break
      case 'more': null
        break
      case 'reset': resetFunction()
        break
      default: null
    }
  }

  // 表格事件分发
  const tableActionEvent = (type, extra) => {
    switch (type) {
      case 'edit':
        editValue.value = JSON.parse(JSON.stringify(extra))
        isShowSidebarActive.value = true
        break
      case 'enable': statusEvent({ id: extra.id })
        break
      case 'disable': statusEvent({ id: extra.id })
        break
      case 'delete': delEvent({ id: extra.id })
        break
      default: null
    }
  }

  /* 权限控制 */

  // 页面启动加载接口
  onMounted(() => { $can('list', 'extension-project-list') ? fetchData(searchParams) : MsgNotice('权限告警', '未获得项目列表的权限') })

  /* 表格相关操作属性及数据 */

  // 适配表格
  const cellClassName = ({ column }) => {
    if (column.property === 'action' || column.property === 'pic') { return 'vxe-table-action-padding' }
  }

  // 查询参数
  const paramsBase = { page: 1, size: 10 }
  // 查询对象 添加自定义查询
  searchParamsBase.forEach(el => { paramsBase[el.param] = el.value })
  const searchParams = reactive(JSON.parse(JSON.stringify(paramsBase)))
  // 设置响应式查询组件参数变量
  const searchParamsObject = ref(JSON.parse(JSON.stringify(searchParamsBase)))

  // vxe-table 加载缓冲控制
  const loading = ref(false)
  // vxe-table 加载缓冲控制
  const xTable = ref(null)
  // 表格数据源
  const tableData = ref([])
  // 记录条数
  const total = ref(0)

  // 更改菜单的可用性 涉及权限，状态属性等
  const setMenuDisabled = (row, param) => {
    const menuInfo = JSON.parse(JSON.stringify(menuData))
    menuInfo.forEach(item => {
      // 判断是否被状态禁用
      const customerDisabled = item.status ? item.status.includes(row[param]) : true
      // 判断是否被权限禁用
      const rule = item.rule ? $can(item.rule.action, item.rule.subject) : true
      // 设置菜单的可用性
      item.disabled = customerDisabled && rule
      return item
    })
    return menuInfo.filter(item => item.disabled)
  }

  /* 接口调用，抽离参数，函数等 */

  // 列表获取接口
  const fetchData = () => {
    projectListApi(searchParams)
      .then(res => {
        tableData.value = res.data.list
        total.value = res.data.total
      })
      .catch(err => { MsgNotice(MsgText.fetchData.fail.title, MsgText.fetchData.fail.text || err.msg) })
  }

  // 状态更新接口
  const statusEvent = id => {
    projectStatusApi(id)
      .then(res => {
        fetchData()
        MsgNotice(
          MsgText.statusEvent.success.title,
          MsgText.statusEvent.success.text || res.msg,
          'CheckCircleIcon',
          'success',
        )
      })
      .catch(err => { MsgNotice(MsgText.statusEvent.fail.title, MsgText.statusEvent.fail.text || err.msg) })
  }

  // 新增或编辑接口
  const editEvent = val => {
    projectSaveApi(val)
      .then(res => {
        fetchData()
        MsgNotice(
          MsgText.editEvent.success.title,
          MsgText.editEvent.success.text || res.msg,
          'CheckCircleIcon',
          'success',
        )
      })
      .catch(err => { MsgNotice(MsgText.editEvent.fail.title, MsgText.editEvent.fail.text || err.msg) })
  }

  // 删除接口
  const delEvent = id => {
    SweetAlert(MsgText.deleteEvent.confirm.title, MsgText.deleteEvent.confirm.text)
    .then(result => {
      if (result.value) {
        projectDeleteApi(id)
          .then(res => {
            fetchData()
            MsgNotice(
              MsgText.deleteEvent.success.title,
              MsgText.deleteEvent.success.text || res.msg,
              'CheckCircleIcon',
              'success',
            )
          })
          .catch(err => { MsgNotice(MsgText.deleteEvent.fail.title, MsgText.deleteEvent.fail.text || err.msg) })
      }
    })
  }

  /* 页面生命周期管理，watch, onMounted, nextTick */

  // 处理参数查询
  watch(() =>[searchParams.page, searchParams.size], () => { fetchData(searchParams) })
  // watch(searchParams, () => { fetchData(searchParams) })
  
  // watch(searchParams, () => { fetchData(searchParams) }, { deep: true })

  // 重置参数 操作处理
  const resetFunction = () => {
    searchParamsObject.value = JSON.parse(JSON.stringify(searchParamsBase))
  }
  // 查询组件 操作处理
  const searchFunction = () => {
    searchParamsObject.value.forEach(el => { searchParams[el.param] = el.value })
    searchParams.page === 1 ? fetchData(searchParams) : searchParams.page = 1
  }

  return {
    // 文本信息
    moduleName,
    moduleText,
    // 表格呈现
    cellClassName,
    loading,
    tableData,
    xTable,
    tableActionEvent,

    // 查询模块
    searchParams,
    searchParamsObject,
    total,
    
    // 接口函数
    fetchData,
    editEvent,
    delEvent,
    statusEvent,
    // 页面事件
    menuData,
    searhPanelButtonEvent,
    setMenuDisabled,
    // 主事件控制区
    buttonLeftOptions,
    buttonRightOptions,
    mainActionEvent,

    // 编辑对话框
    blankEditParams,
    editValue,
    isShowSidebarActive,
  }
}
